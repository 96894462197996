import React from "react";

import PrimaryButton from "components/common/Buttons/Primary";
import { classNames } from "react-select/src/utils";

import BaseModal, { IBaseModalProps } from "./BaseModal";

interface IConfirmDareModal extends IBaseModalProps {
  creator: string;
  title: string;
  coins: number;
  onClick?: () => Promise<void>;
  disabled?: boolean;
}

const ConfirmDareModal: React.FunctionComponent<IConfirmDareModal> = ({
  creator,
  title,
  coins,
  onClick,
  disabled,
  ...props
}) => {
  return (
    <BaseModal className="w-72 lg:w-80 text-center z-30" {...props}>
      <div className="p-2">
        <h5>Place your dare</h5>
        <h6 className="font-normal">{`You have dared “${creator}”`}</h6>
        <h6 className="font-normal">{`“${title}”`}</h6>
        <h6 className="pt-4 font-normal">
          {` It costs you ${coins} coins. You cannot change it once you have submmited
        the request.`}
        </h6>
        <PrimaryButton
          onClick={onClick}
          className="w-full mt-3 px-3 py-2"
          disabled={disabled}
        >
          Confirm
        </PrimaryButton>
      </div>
    </BaseModal>
  );
};

export default ConfirmDareModal;
