import React, { useState } from "react";

import { PollResponse, dare } from "api/poll";
import PrimaryButton from "components/common/Buttons/Primary";
import AvatarGroup from "components/Icons/AvatarGroup";
import ConfirmDareModal from "components/Modals/ConfirmDare";
import TopSection from "components/Sections/Top";
import DareToast from "components/Toasts/Dare";
import { PageProps } from "gatsby";
import useGlobalContext from "hooks/common/useGlobalContext";

interface LocationState {
  poll: PollResponse
  input: {
    title: string,
    amount: number
  }
}

interface IConfirmationProps extends PageProps<{}, {}, LocationState> {}

const Confirmation: React.FunctionComponent<IConfirmationProps> = ({ location }) => {
  const { globalState } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleModalClick = async () => {
    setLoading(true);
    try {
      const res = await dare({
        pollId: location.state.poll.id,
        title: location.state.input.title,
        balance: location.state.input.amount,
      });
      
      if (res) {
        setModalOpen(false);
        setToastOpen(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(123, location.state.poll)

  return (
    <div>
      <ConfirmDareModal
        isOpen={modalOpen}
        title={location.state.input.title}
        creator={location.state.poll.creator.displayName}
        coins={location.state.input.amount}
        onClick={handleModalClick}
        disabled={loading}
        onClose={handleCloseModal}
      />
      <DareToast isOpen={toastOpen} />
      <TopSection title="Game On" />

      <div className="p-4 mt-2 shadow-xl rounded-xl">
        <AvatarGroup
          left={location.state.poll.creator.photoURL!}
          right={globalState.user.photoURL!}
          className={"w-32 h-32"}
        />
        <div className="m-2">
          <div className="h-auto  p-2 bg-neutral-50 text-neutral-800 rounded-xl">
            {location.state.input.title}
          </div>

          <footer className="italic text-right font-light">
            by @{globalState.user.displayName}
          </footer>
        </div>
        <footer className="italic  font-light text-neutral-800">
          {"  We will let you know once “Creator” accept your request!"}
        </footer>

        <footer className="italic font-light text-neutral-800 mt-3">
          {
            "   We will only deduct your balance once creator accepted your request. If your request is not accepted, you will get the full coins refund."
          }
        </footer>
      </div>
      <PrimaryButton onClick={handleClick} className="w-full mt-4 px-3 py-2">
        Go to Dare
      </PrimaryButton>
    </div>
  );
};

export default Confirmation;
