import React from "react";

import { CreatoIcon } from "components/Icons/CreatoIcon";

import BaseToast, { IBaseToastProps } from "./BaseToast";

const DareToast: React.FunctionComponent<IBaseToastProps> = ({ ...props }) => {
  return (
    <BaseToast
      className="w-64  bg-shades-0 lg:w-96"
      imageClassName={"w-16 px-4 py-2"}
      image={<CreatoIcon className="fill-primary-500" aria-hidden="true"/>}
      {...props}
    >
      <h4 className="mt-2">Thank you!</h4>
      <h5 className="font-normal mt-2 ">You have made a Dare! </h5>
    </BaseToast>
  );
};
export default DareToast;
